<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Allowance Category">
      <template #page-header>
        <h2 class="page-title">Allowance Setting</h2>
        <r-menu-tab :level="3" />
      </template>
      <template #action-bar>
        <div class="level">
          <div class="level-left">
            <b-button
              tag="button"
              class="is-command"
              icon-left="plus"
              @click.native="openModalCreate()"
            >
              New
            </b-button>
          </div>
        </div>
      </template>

      <template #page-content>
        <div id="table-allowance-type" class="table-timeoff-type">
          <b-table
            :data="allowanceList"
            :per-page="perPage"
            :loading="isAllowanceTypeListLoading"
            @page-change="onPageChange"
            :show-detail-icon="showDetailIcon"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkbox-position="left"
            class="employment-directory-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column label="No" v-slot="props" width="5%">
                <span class="is-capitalize">
                  {{ props.index + 1 }}
                </span>
              </b-table-column>
              <b-table-column
                field="name"
                label="Allowance Name"
                v-slot="props"
                sortable
                width="67%"
              >
                <span class="is-capitalize">{{ props.row.name }}</span>
              </b-table-column>
              <b-table-column label="Action" v-slot="props" width="8%">
                <b-icon
                  type="is-primary"
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="employment-edit-icon"
                  @click.native="openModalCreate(props.row)"
                ></b-icon>
                <b-icon
                  type="is-primary"
                  icon="delete"
                  custom-size="mdi-18px"
                  class="timeoff-type-delete-icon"
                  @click.native="deletionWarning(props.row.id)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <AllowanceCategoryModal
          :show="isModalOpen"
          :formData="data"
          :loadingSubmit="isSubmitting"
          :isEditing="isEditing"
          @hide="closeModalCreate"
          @submit="submit"
        />
      </template>
    </r-page>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showToast } from '@/services/util'
import AllowanceCategoryModal from './AllowanceCategoryModal.vue'

export default {
  data() {
    return {
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      defaultSortOrder: 'desc',
      sortField: 'name',
      sortOrder: 'desc',
      search: '',
      showDetailIcon: true,
      isAllowanceTypeListLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,
      data: {
        id: '',
        name: null,
        createdAt: null,
        updatedAt: null,
      },
      isSubmitting: null,
    }
  },
  computed: {
    ...mapGetters({
      allowanceList: 'allowanceModule/getAllowanceList',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadAllowanceList: 'allowanceModule/getAllowanceList',
      saveAllowanceType: 'allowanceModule/saveAllowanceType',
      editAllowanceType: 'allowanceModule/editAllowanceType',
      deleteAllowanceType: 'allowanceModule/deleteAllowanceType',
    }),
    ...mapMutations({
      setAllowanceList: 'allowanceModule/setAllowanceList',
    }),

    /**
     * Load allowance type data list
     */
    async loadAllowanceTypeList() {
      this.isAllowanceTypeListLoading = true
      try {
        const response = await this.actionLoadAllowanceList({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
        })
        this.total = response.data.total
        this.lastPage = response.data.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isAllowanceTypeListLoading = false
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Change page behavior
     * @param {integer} page - number of page
     */
    onPageChange(page) {
      this.page = page
      this.loadAllowanceTypelist()
    },

    /**
     * Reset state of table
     */
    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setAllowanceList([])
      await this.loadAllowanceTypelist()
    },

    /**
     * Load more table data
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadAllowanceTypelist()
        this.isLoadMore = false
      }
    },

    /**
     * Open create modal. If row present,
     * set form to include data from selected table row
     * @param {integer} row - table row index (optional)
     */
    openModalCreate(row) {
      if (row) {
        this.isEditing = true
        this.data = {
          id: row.id,
          name: row.name,
        }
      }
      this.isModalOpen = true
    },

    /**
     * Close create modal
     */
    closeModalCreate() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    /**
     * Reset form when modal is closed
     */
    resetForm() {
      this.data = {
        id: '',
        name: null,
      }
    },

    /**
     * submit release note data
     */
    async submit() {
      this.isSubmitting = true
      let form = new FormData()
      try {
        if (!this.isEditing) {
          form.append('name', this.data.name)
          await this.saveAllowanceType(form)
          showToast('Data is successfully added!', 'is-success', 'is-top')
        } else {
          await this.editAllowanceType(this.data)
          showToast('Data is successfully edited!', 'is-success', 'is-top')
        }
      } catch (e) {
        showToast(e.response.message, 'is-danger', 'is-top')
      }
      this.isSubmitting = false
      this.isEditing = false
      this.resetForm()
      this.isModalOpen = false
    },

    /**
     * Open warning sweet alert when deleting a data
     * @param {integer} id - selected ID from database
     */
    deletionWarning(id) {
      this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        denyButtonText: 'No',
        inputValue: 1,
        customClass: {
          confirmButton: 'button is-primary',
          denyButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: 'Are you sure you want to delete this data?',
      }).then(async (res) => {
        try {
          if (res && res.isConfirmed) {
            await this.deleteAllowanceType(id)
            this.$swal({
              icon: 'success',
              titleText: 'Data is successfully deleted!',
              confirmButtonText: 'Done',
              customClass: {
                confirmButton: 'button is-success',
              },
            })
          }
        } catch (e) {
          this.$swal({
            icon: 'error',
            titleText: 'Failed. Please try again!',
            confirmButtonText: 'Go Back Home',
            customClass: {
              confirmButton: 'button is-grey',
            },
          })
        }
      })
    },
  },
  async mounted() {
    this.setAllowanceList([])
    await this.loadAllowanceTypeList()
  },
  components: { AllowanceCategoryModal },
}
</script>
